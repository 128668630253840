import { BreadcrumbResource } from "../components/breadcrumbs/breadcrumbs"
import { PathPrefix, Store } from "./constants"

const HomepageBreadcrumb: BreadcrumbResource = {
  label: "Home",
  link: "/"
}

const LearnBreadcrumb: BreadcrumbResource = {
  label: "Learn",
  link: `${PathPrefix.Learn}`
}

const ArticlesBreadcrumb: BreadcrumbResource = {
  label: "Articles",
  link: `${PathPrefix.Articles}`
}

const PostBreadcrumb: BreadcrumbResource = {
  label: "Post",
  link: `${PathPrefix.Post}`
}

const NewsBreadcrumb: BreadcrumbResource = {
  label: "News",
  link: `${PathPrefix.Articles}`
}

const TeachingSeriesBreadcrumb: BreadcrumbResource = {
  label: "Teaching Series",
  link: `${PathPrefix.Series}`
}

const ConferencesBreadcrumb: BreadcrumbResource = {
  label: "Conference Messages",
  link: `${PathPrefix.Conferences}`
}

const DevotionalsBreadcrumb: BreadcrumbResource = {
  label: "Devotionals",
  link: `${PathPrefix.Devotional}`
}

const QuestionsBreadcrumb: BreadcrumbResource = {
  label: "Questions Answered",
  link: `${PathPrefix.QuestionsAnswered}`
}

const TeachersBreadcrumb: BreadcrumbResource = {
  label: "Teachers",
  link: `${PathPrefix.Teachers}`
}

const ScripturesBreadcrumb: BreadcrumbResource = {
  label: "Scriptures",
  link: `${PathPrefix.Scriptures}`
}

const CollectionsBreadcrumb: BreadcrumbResource = {
  label: "Collections",
  link: `${PathPrefix.Collections}`
}

const StoreBreadcrumb: BreadcrumbResource = {
  label: "Store",
  link: `${Store}`
}

const SearchBreadcrumb: BreadcrumbResource = {
  label: "Search Results",
  link: `${PathPrefix.Search}`
}

const AboutBreadcrumb: BreadcrumbResource = {
  label: "About",
  link: `${PathPrefix.About}`
}

const WhoWeAreBreadcrumb: BreadcrumbResource = {
  label: "Who We Are",
  link: `${PathPrefix.WhoWeAre}`
}

const WhatWeBelieveBreadcrumb: BreadcrumbResource = {
  label: "What We Believe",
  link: `${PathPrefix.WhatWeBelieve}`
}

const FinancialInformationBreadcrumb: BreadcrumbResource = {
  label: "Financial Information",
  link: `${PathPrefix.FinancialInformation}`
}

const EmploymentBreadcrumb: BreadcrumbResource = {
  label: "Employment",
  link: "https://careers.ligonier.org"
}

const SupportBreadcrumb: BreadcrumbResource = {
  label: "Supporting Ligonier",
  link: `${PathPrefix.Support}`
}

export const PageBreadcrumbs = {
  About: [HomepageBreadcrumb, AboutBreadcrumb],
  WhoWeAre: [HomepageBreadcrumb, AboutBreadcrumb, WhoWeAreBreadcrumb],
  WhatWeBelieve: [HomepageBreadcrumb, AboutBreadcrumb, WhatWeBelieveBreadcrumb],
  FinancialInformation: [
    HomepageBreadcrumb,
    AboutBreadcrumb,
    FinancialInformationBreadcrumb
  ],
  Support: [HomepageBreadcrumb, AboutBreadcrumb, SupportBreadcrumb],
  Employment: [HomepageBreadcrumb, EmploymentBreadcrumb],
  Learn: [HomepageBreadcrumb, LearnBreadcrumb],
  Articles: [HomepageBreadcrumb, LearnBreadcrumb, ArticlesBreadcrumb],
  Post: [HomepageBreadcrumb, PostBreadcrumb],
  News: [HomepageBreadcrumb, LearnBreadcrumb, NewsBreadcrumb],
  Collections: [HomepageBreadcrumb, LearnBreadcrumb, CollectionsBreadcrumb],
  Conferences: [HomepageBreadcrumb, LearnBreadcrumb, ConferencesBreadcrumb],
  Devotionals: [HomepageBreadcrumb, LearnBreadcrumb, DevotionalsBreadcrumb],
  QuestionsAnswered: [HomepageBreadcrumb, LearnBreadcrumb, QuestionsBreadcrumb],
  Scriptures: [HomepageBreadcrumb, LearnBreadcrumb, ScripturesBreadcrumb],
  Search: [HomepageBreadcrumb, SearchBreadcrumb],
  Store: [HomepageBreadcrumb, StoreBreadcrumb],
  StoreCollection: [HomepageBreadcrumb, StoreBreadcrumb],
  TeachingSeries: [
    HomepageBreadcrumb,
    LearnBreadcrumb,
    TeachingSeriesBreadcrumb
  ],
  Teachers: [HomepageBreadcrumb, LearnBreadcrumb, TeachersBreadcrumb]
}
