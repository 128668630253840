import { TopicFragment } from "../../../../graphql/types"
import { TopLevelTopic } from "../topic-types"

export const mapTopLevelTopic = ({
  name,
  topicCode,
  slug
}: TopicFragment): TopLevelTopic => ({
  name: name || "",
  topicCode: topicCode || "",
  slug: slug || ""
})

export const mapTopLevelTopics = (
  topicFragments: TopicFragment[]
): TopLevelTopic[] => topicFragments.map(mapTopLevelTopic)
