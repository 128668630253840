import {
  ProductDetailsFragment,
  ContentfulTeacher,
  VariantWithProductFragment,
  ContentfulTopic,
  TopicFragment
} from "../../../../graphql/types"
import { mapTeachers } from "../../../core/mappers/map-teachers"
import { ProductDetails, SampleFile, ProductFeature } from "../store-types"
import { Store } from "../../../core/constants"
import { mapTopLevelTopics } from "../../topics/mappers/topics-mapper"
import { formatDate, formatVariantTitle } from "../store-helpers"
import {
  mapProductVariantToView,
  mapProductVariantsToView
} from "./product-variants-mapper"
import { TopLevelTopic } from "@features/topics/topic-types"

// don't like this :/
export const sampleFileMap = (sampleFile: any): Array<SampleFile> => {
  return (
    sampleFile?.map?.((file: SampleFile) => ({
      alt: file.alt || ``,
      filename: file.filename || ``,
      handle: file.handle || ``,
      id: file.id || ``,
      key: file.key || ``,
      media_type: file.media_type || ``,
      mime_type: file.mime_type || ``,
      original_src: file.original_src || ``,
      scope: file.scope || ``,
      src: file.src || ``
    })) || []
  )
}

export const mapAdditionalInfo = (
  features: any
): ProductFeature[] | undefined => {
  return features?.map((feature: any) => ({
    image: feature?.image,
    subtitle: feature?.subtitle,
    description: feature?.description,
    title: feature?.title
  }))
}

export const mapVariantDetailsToView = (
  productVariant: VariantWithProductFragment
): ProductDetails => {
  if (!productVariant.product) {
    throw new Error("No product data")
  }

  const product = productVariant.product // parent product
  const variant = mapProductVariantToView(productVariant)
  const variants = productVariant.product.variants
    ? mapProductVariantsToView(productVariant.product.variants)
    : []

  const teachers = productVariant.product.teachers
    ? mapTeachers(
        productVariant.product.teachers as Partial<ContentfulTeacher>[]
      )
    : []

  const topics = productVariant.product.topics
    ? mapTopLevelTopics(productVariant.product.topics as TopicFragment[])
    : []

  return {
    id: product.id,
    title: product.title || "",
    subtitle: product.subtitle || "",
    type: product.productType || "",
    description: product.description || "",
    image: product.image || "",
    teachers,
    topics,
    primaryTopic: (productVariant.product.primaryTopic as TopLevelTopic) || {},
    features: mapAdditionalInfo(product.additionalInfo),
    vendor: "Ligonier Ministries",
    handle: variant.handle!,
    link: `${Store}/${variant.handle}`,
    embeddedContent: product.embeddedContent || undefined,
    sampleFile: sampleFileMap(
      productVariant.sampleFile?.length
        ? productVariant.sampleFile
        : product.sampleFile
    ),
    language: product.language,
    productInfo: {
      format: variant.format || formatVariantTitle(variant.title),
      isbn: variant.barcode,
      publication: formatDate(variant.publicationDate),
      color: variant.color,
      language: variant.language
    },
    variants,
    activeVariant: variant
  }
}
