import en from "./data/en.json"
import search from "./data/search.json"
import messagesFor404 from "./data/404.json"
import store from "./data/store.json"
import general from "./data/general.json"
import devotional from "./data/devotional.json"
import events from "./data/events.json"
import callouts from "./data/callouts.json"
import about from "./data/about.json"
import header from "./data/header.json"
import footer from "./data/footer.json"
import featured from "./data/featured.json"
import dailyVideo from "./data/daily-video.json"
import podcasts from "./data/podcasts.json"
import conference from "./data/conference.json"
import teachingSeries from "./data/teaching-series.json"
import homepage from "./data/homepage.json"
import metadata from "./data/metadata.json"
import topic from "./data/topic.json"
import scripture from "./data/scripture.json"
import es from "./data/es.json"
import collection from "./data/collection.json"
import { LocaleCode } from "./locales"

export type Messages = {
  [code in LocaleCode]: {
    [key: string]: string
  }
}

// Spread all groups of messages into one object
// Also add group name (object key) as prefix to message ID
// e.g. search["panel-reset"] spreads into new object under key "search-panel-reset"
const flattenAndPrefixMessages = (
  sections: Record<string, Record<string, string>>
): Record<string, string> => {
  const messages: Record<string, string> = {}

  for (const sectionName in sections) {
    const sectionMessages = sections[sectionName]

    for (const messageId in sectionMessages) {
      const message = sectionMessages[messageId]
      const prefixedId = `${sectionName}-${messageId}`

      messages[prefixedId] = message
    }
  }

  return messages
}

export const defaultLocale: LocaleCode = "en-US"

// For use with Jest tests...
// All messages other than en.json used to come from Contentful in the codebase,
// but they weren't fetched from Contentful for the tests, so the tests were
// just written assuming those messages are missing :/
export const testMessages: Messages = {
  "en-US": en,
  es
}

export const messages: Messages = {
  "en-US": {
    ...en,
    ...flattenAndPrefixMessages({
      search,
      "404": messagesFor404,
      store,
      general,
      devotional,
      events,
      callouts,
      about,
      header,
      footer,
      featured,
      "daily-video": dailyVideo,
      podcasts,
      conference,
      "teaching-series": teachingSeries,
      homepage,
      metadata,
      topic,
      collection,
      scripture
    })
  },
  es
}
